.reviews__block
    .reviews__item
        padding 24px
        border 1px solid gray
        &:not(:last-child)
            border-bottom none
        .content
            .info
                display flex
                justify-content space-between
                .author
                    font-weight 500
                    font-size 16px
                .date
                    color color-font-light
            .rating__block
                margin-left auto
                margin-top 12px
                .rating__item
                    margin-right 6px
                    svg
                        width 15px
                        height 13px
                        fill gray-dark
                    &.filled
                        svg
                            fill color-secondary
            .text
                margin-top 12px
                
        