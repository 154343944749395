.product__item
    &___compare
        .options
            margin-top 24px
            .options__list
                .options__item
                    display flex
                    justify-content space-between
                    margin-top 12px
                    .title
                        width 50%
                        font-weight 500
                        color color-font-light
                    .value
                        display block
                        width 50%
                        text-align left
                        word-break break-word
                        font-weight 500
    &___grid
        position relative
        margin-bottom 24px
        .content
            position relative
            display flex
            flex-direction column
            padding 20px
            background white
            border 1px solid gray
            round 3px
            z-index 2
            .action__buttons
                absolute(25px, 25px)
                display block
                .button
                    position relative
                    display none
                    width 32px
                    height 32px
                    margin-top 6px
                    background color-main
                    round 3px
                    cursor pointer
                    z-index 2
                    .icon
                        display flex
                        padding 6px
                        align-items center
                        justify-content center
                        svg
                            fill white
                    &.active
                        display block
                        background color-main-dark
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                .favorites__button
                    .icon
                        svg
                            width 24px
                            height 20px
                .compare__button
                    .icon
                        svg
                            width 38px
                            height 20px
                            stroke white
                .buy_one_click__button
                    display none!important
                    .icon
                        svg
                            width 1233
                            height 1233
            .gallery
                position relative
                display block
                min-width 60px
                width 100%
                margin 0 auto 20px
                padding 0
                padding-top 100%
                .link
                    absolute(0, auto, 0, 0)
                    display block
                    min-width 60px
                    width 100%
                    margin 0 auto
                    padding 0
                    .wrapper
                        absolute0()
                        display flex
                        flex-direction row
                        z-index 2
                        .image
                            flex-grow 1
                            -webkit-box-flex 1
                            -ms-flex-positive 1
                            background white
                            background 0 0
                            margin-bottom -4px
                            .nav
                                position relative
                                display block
                                height 100%
                                margin 0 2px 0 0
                                &::before
                                    content ''
                                    absolute(auto, 0, 0, 0)
                                    display block
                                    height 2px
                                    background-color gray-dark
                                    -webkit-transition background-color .2s ease-in-out
                                    transition background-color .2s ease-in-out
                                    cursor pointer
                            img
                                absolute0()
                                display block
                                max-width 100%
                                min-width 100%
                                max-height 100%
                                height auto
                                border 0
                                opacity 0
                                transition opacity .2s ease-in-out
                                pointer-events none
                            &:last-of-type
                                .nav
                                    margin-right 0
                            &.active
                                .nav
                                    &::before
                                        background-color color-main
                                img
                                    opacity 1
            .info
                display flex
                flex-direction column
                height 144px
                .title.link
                    display block
                    height 72px
                    margin-bottom 20px
                    font-weight 500
                    color color-font
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
                .stock
                    font-weight 600
                    color color-main
                .prices
                    margin-top 12px
                    .price
                        display block
                        font-size 18px
                        font-weight 600
                        &:not(.request_price)
                            &::after
                                content '₽'
                                margin-left 6px
                        &.request_price
                            font-size 16px
                            font-weight 700
                        &.old_price
                            text-decoration line-through
                            color color-font-light
                        
        .hidden_info
            absolute(100%, 12px, auto, 12px)
            display none
            background white
            border 1px solid gray
            border-top none
            round-bottom(3px)
            z-index 3
            .buy__buttons
                .button
                    width 100%
                    height 46px
                    border none
                    outline none
                    background color-main
                    font-weight 500 
                    color white
                    cursor pointer
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                        
            .props
                padding 20px
                &__item
                    display flex
                    justify-content space-between
                    margin-bottom 6px
                    span
                        display block
                        font-size 12px
                        word-break break-word
                    .title
                        font-weight 500
                        color color-font
                    .value
                        color color-font-light
        &:hover
            .content
                box-shadow 0 7px 10px 0 rgba(color-font, 30%)
                .action__buttons
                    .button
                        display block
            .hidden_info
                display block
                box-shadow 0 7px 10px 0 rgba(color-font, 30%)
    &___list
        display block
        position relative
        margin-bottom 24px
        .content
            position relative
            display flex
            flex-direction column
            padding 20px
            background white
            border 1px solid gray
            round 3px
            z-index 2
            .gallery
                position relative
                display block
                min-width 60px
                width 100%
                margin 0 auto 20px
                padding 0
                padding-top 100%
                .link
                    absolute(0, auto, 0, 0)
                    display block
                    min-width 60px
                    width 100%
                    margin 0 auto
                    padding 0
                    .wrapper
                        absolute0()
                        display flex
                        flex-direction row
                        z-index 2
                        .image
                            flex-grow 1
                            -webkit-box-flex 1
                            -ms-flex-positive 1
                            background white
                            background 0 0
                            margin-bottom -4px
                            .nav
                                position relative
                                display block
                                height 100%
                                margin 0 2px 0 0
                                &::before
                                    content ''
                                    absolute(auto, 0, 0, 0)
                                    display block
                                    height 2px
                                    background-color gray-dark
                                    -webkit-transition background-color .2s ease-in-out
                                    transition background-color .2s ease-in-out
                                    cursor pointer
                            img
                                absolute0()
                                display block
                                max-width 100%
                                max-height 100%
                                height auto
                                border 0
                                opacity 0
                                transition opacity .2s ease-in-out
                                pointer-events none
                            &:last-of-type
                                .nav
                                    margin-right 0
                            &.active
                                .nav
                                    &::before
                                        background-color color-main
                                img
                                    opacity 1
            .title.link
                display block
                margin-bottom 20px
                font-weight 500
                color color-font
                word-break break-word
                &:hover
                    color color-main-light
                &:active
                    color color-main-dark
            .stock
                font-weight 600
                color color-main
            .prices
                margin-top 12px
                .price
                    display block
                    font-size 16px
                    font-weight 600
                    &:not(.request_price)
                        &::after
                            content '₽'
                            margin-left 6px
                    &.request_price
                        font-size 16px
                        font-weight 700
                    &.old_price
                        text-decoration line-through
                        color color-font-light
            .buy__buttons
                margin-top 12px
                .button
                    width 100%
                    height 46px
                    border none
                    outline none
                    background color-main
                    font-weight 500 
                    color white
                    cursor pointer
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                        
            .props
                padding 20px
                &__item
                    display flex
                    justify-content space-between
                    margin-bottom 6px
                    span
                        display block
                        font-size 12px
                        word-break break-word
                    .title
                        font-weight 500
                        color color-font
                    .value
                        color color-font-light
            .action__buttons
                display block
                .button
                    position relative
                    width 32px
                    height 32px
                    margin-top 6px
                    background color-main
                    round 3px
                    cursor pointer
                    z-index 2
                    .icon
                        display flex
                        padding 6px
                        align-items center
                        justify-content center
                        svg
                            fill white
                    &.active
                        display block
                        background color-main-dark
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                .favorites__button
                    .icon
                        svg
                            width 24px
                            height 20px
                .compare__button
                    .icon
                        svg
                            width 38px
                            height 20px
                            stroke white
                .buy_one_click__button
                    display none!important
                    .icon
                        svg
                            width 1233
                            height 1233
                        
    &___slider
        position relative
        margin-bottom 24px
        .content
            position relative
            display flex
            flex-direction column
            padding 20px
            background white
            border 1px solid gray
            round 3px
            z-index 2
            .action__buttons
                absolute(25px, 25px)
                display block
                .button
                    position relative
                    display none
                    width 32px
                    height 32px
                    margin-top 6px
                    background color-main
                    round 3px
                    cursor pointer
                    z-index 2
                    .icon
                        display flex
                        padding 6px
                        align-items center
                        justify-content center
                        svg
                            fill white
                    &.active
                        display block
                        background color-main-dark
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                .favorites__button
                    .icon
                        svg
                            width 24px
                            height 20px
                .compare__button
                    .icon
                        svg
                            width 38px
                            height 20px
                            stroke white
                .buy_one_click__button
                    display none!important
                    .icon
                        svg
                            width 1233
                            height 1233
            .gallery
                position relative
                display block
                min-width 60px
                width 100%
                margin 0 auto 20px
                padding 0
                padding-top 100%
                .link
                    absolute(0, auto, 0, 0)
                    display block
                    min-width 60px
                    width 100%
                    margin 0 auto
                    padding 0
                    .wrapper
                        absolute0()
                        display flex
                        flex-direction row
                        z-index 2
                        .image
                            flex-grow 1
                            -webkit-box-flex 1
                            -ms-flex-positive 1
                            background white
                            background 0 0
                            margin-bottom -4px
                            .nav
                                position relative
                                display block
                                height 100%
                                margin 0 2px 0 0
                                &::before
                                    content ''
                                    absolute(auto, 0, 0, 0)
                                    display block
                                    height 2px
                                    background-color gray-dark
                                    -webkit-transition background-color .2s ease-in-out
                                    transition background-color .2s ease-in-out
                                    cursor pointer
                            img
                                absolute0()
                                display block
                                max-width 100%
                                max-height 260px
                                height auto
                                border 0
                                opacity 0
                                transition opacity .2s ease-in-out
                                pointer-events none
                            &:last-of-type
                                .nav
                                    margin-right 0
                            &.active
                                .nav
                                    &::before
                                        background-color color-main
                                img
                                    opacity 1
            .info
                display flex
                flex-direction column
                .title.link
                    display block
                    margin-bottom 20px
                    font-weight 500
                    color color-font
                    word-break break-word
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
                .stock
                    font-weight 600
                    color color-main
                .prices
                    margin-top 12px
                    .price
                        display block
                        font-size 18px
                        font-weight 600
                        &:not(.request_price)
                            &::after
                                content '₽'
                                margin-left 6px
                        &.request_price
                            font-size 16px
                            font-weight 700
                        &.old_price
                            text-decoration line-through
                            color color-font-light
        .hidden_info
            absolute(100%, 0, auto, 0)
            display none
            background white
            border 1px solid gray
            border-top none
            round-bottom(3px)
            z-index 3
            .buy__buttons
                .button
                    width 100%
                    height 46px
                    border none
                    outline none
                    background color-main
                    font-weight 500 
                    color white
                    cursor pointer
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                        
            .props
                padding 20px
                &__item
                    display flex
                    justify-content space-between
                    margin-bottom 6px
                    span
                        display block
                        font-size 12px
                        word-break break-word
                    .title
                        font-weight 500
                        color color-font
                    .value
                        color color-font-light
        &:hover
            box-shadow 0 7px 10px 0 rgba(color-font, 30%)
            .content
                .action__buttons
                    .button
                        display block
            .hidden_info
                display block
                box-shadow 0 7px 10px 0 rgba(color-font, 30%)