.calc__form
    border 1px solid gray
    round 3px
    .props
        display flex
        flex-wrap wrap
        padding 32px
        .props__item
            display flex
            flex-direction column
            max-width calc(50% - 12px)
            margin-bottom 12px
            margin-right 24px
            .title
                margin-bottom 6px
            .props_values
                position relative
                display flex
                align-items center
                &__value
                    display flex
                    align-items center
                    justify-content space-between
                    min-width 200px
                    padding 12px
                    border 1px solid gray
                    round 3px
                    text-transform capitalize
                    cursor pointer
                    span
                        display block
                        overflow hidden
                        white-space nowrap
                        text-overflow ellipsis
                    .arrow
                        width 12px
                        height 6px
                        margin-left 12px
                        transform rotate(180deg)
                        -webkit-transition transform .2s ease-in-out
                        -moz-transition transform .2s ease-in-out
                        -o-transition transform .2s ease-in-out
                        transition transform .2s ease-in-out
                &__list
                    absolute(100%, 0, auto, 0)
                    display none
                    background white
                    border 1px solid gray
                    z-index 4
                    round-bottom(3px)
                    .props_values__item
                        padding 6px 12px
                        margin  6px 0
                        text-transform capitalize
                        cursor pointer
                        &:hover
                            color color-main-light
                        &:active
                            color color-main-dark
                        &.selected
                            color color-main-dark
                        
            &.active
                .title
                    .arrow
                        transform rotate(360deg)
                .props_values
                    &__list
                        display block
    .form_footer
        display flex
        flex-wrap wrap
        justify-content space-between
        padding 32px  
        background gray-light
        border-top 1px solid gray   
    .form_results
        width calc(50% - 12px)
        .price
            font-weight 500
    .form__items
        display flex
        flex-wrap wrap
        justify-content space-between
        width calc(50% - 12px)
        .form__item
            width 100%
            margin-bottom 24px
        .form__input
            display flex
            align-items center
            width 100%
            padding 12px
            background gray-light
            outline none
            border 1px solid gray
            round 3px
            &:focus
                padding 11px
                border 2px solid gray-dark
        .form__button
            display flex
            align-items center
            justify-content center
            width 100%
            padding 12px
            font-weight 500
            color color-main
            background white
            border 2px solid color-main
            round 3px
            cursor pointer
            &.submit
                color white
                background color-main
            &:hover
                color white
                background color-main-light
                border 2px solid color-main-light
            &:active
                color white
                background color-main-dark
                border 2px solid color-main-dark
        