.articles__block
    position relative
    margin-top -36px
    padding 72px 0 36px 0
    &::after
        content ''
        absolute(0)
        width 100%
        height 100%
        background gray-light
        z-index -2
    .article__item
        &___home
            margin-bottom 24px
            .content
                display flex
                flex-direction column
                overflow hidden
                .image.link
                    position relative
                    display block
                    height 200px
                    background linear-gradient(0, rgba(black, 0.1) 0, rgba(black, 0.1) 100%)
                    &:hover
                        background linear-gradient(0, rgba(black, 0.3) 0, rgba(black, 0.1) 100%)
                    img
                        absolute()
                        width 100%
                        height 100%
                        round 3px
                        z-index -1
                    .published
                        absolute(0, auto, 0, auto)
                        display flex
                        flex-direction column
                        height 72px
                        padding 6px 12px
                        background color-main
                        text-align center
                        color white
                        round 3px
                        .day
                            font-size 22px
                        .month
                            font-size 14px
                            text-transform uppercase
                        .year
                            font-size 12px
                .info.link
                    display flex
                    flex-direction column
                    margin-top 12px
                    .title
                        text-align left
                        font-size 18px
                        font-weight 600
                        color color-font
                    .text
                        max-height 70px
                        text-align left
                        color color-font-light
                    &:hover
                        .title
                            color color-main-light
                        .text
                            color color-main-light
                    &:active
                        .title
                            color color-main-dark
                        .text
                            color color-main-dark
                                
                