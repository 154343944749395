.category__item
    background red
    &___cards_big
        position relative
        margin-top 24px
        .content
            .image
                display flex
                width 100%
                height 100%
                img
                    width 100%
                    height 100%
            .title
                absolute(32px, 'auto', 'auto', 32px)
                max-width 80%
                color color-font
                font-size 16px
                font-weight 600
                &:hover
                    color color-main-light
                &:active
                    color color-main-dark
        &:hover
            .content
                box-shadow 0 7px 10px 0 rgba(color-font, 30%)
                
                