header
    &.desktop
        background white
        .container
                height 100%
            .flex
                display flex
                align-items center
                justify-content space-between
                height 100%
        & > .topbar
            height 54px
            background gray-light
            .location
                display flex
                align-items center
                .icon
                    display block
                    height 26px
                    width 20px
                    fill color-main
                    margin-right 8px
                .info
                    display flex
                    flex-direction column
                    .city
                        color color-main
                        font-weight 600
                        text-transform uppercase
                    .work_time
                        font-size 12px
                        color color-font-light
            .social_icons
                display flex
                align-items center
                justify-content space-between
                width 150px
                height 100%
                margin-left 50px
                a
                    display block
                    height 26px
                    svg
                        width 26px
                        height 26px
                        fill rgba(gray-darken, 35%)
                .viber
                    &:hover
                        svg
                            fill #7D519D
                    &:active
                        svg
                            fill #4C2A65
               .whatsapp
                    &:hover
                        svg
                            fill #49CB5B
                    &:active
                        svg
                            fill #229231
               .tg
                    &:hover
                        svg
                            fill #2CA5DA
                    &:active
                        svg
                            fill #385779
               .mail
                    &:hover
                        svg
                            fill #EB6216
                    &:active
                        svg
                            fill #C54904
            nav.top
                flex-grow 1
                text-align right
                .menu__item
                    margin-left 45px
                    padding 5px 0
                    color gray-darken
                    font-weight 500
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
        & > .info
            height 110px
            border-top 1px solid gray
            border-bottom 1px solid gray
            .logo
                display flex
                height 72px
                .image
                    width 160px
                    height 72px
                    svg
                        width 160px
                        height 72px
                .text
                    display flex
                    align-items center
                    margin-left 20px
                    span
                        display block
                        width 200px
                        padding-left 20px
                        border-left 1px solid gray
                        font-size 13px
                        font-weight 500
                        color rgba(color-font, 30%)
            .search
                flex-grow 1
                margin 0 30px
                form
                    position relative
                    height 46px
                    input
                        height 46px
                        width 100%
                        min-width 250px
                        padding 16px 46px 16px 22px
                        background rgba(gray, 30%)
                        outline none
                        border 1px solid rgba(gray-dark, 30%)
                        round 3px
                        font-weight 500
                        color color-font-light
                        +mq(lg-max)
                            min-width 200px
                        &:focus
                            padding 15px 45px 15px 21px
                            border 2px solid color-main
                            background #fff
                            color color-font
                    .search_icon
                        absolute(13px, 22px)
                        width 20px
                        height 20px
                        cursor pointer
                        svg
                            width 20px
                            height 20px
                            fill color-font
                        &:hover
                            svg
                                fill color-main
                        &:active
                            svg
                                fill color-main-light
            .contact
                width 168px
                min-width 168px
                margin-right 30px
                .phone
                    display block
                    margin-bottom 3px
                    font-size 17px
                    font-weight 600
                    color color-font
                .callback
                    span
                        font-size 14px
                        font-weight 400
                        text-decoration underline
                        color color-main
                        cursor pointer
                        &:hover
                            color color-main-light
                        &:active
                            color color-main-dark
            .btns
                display flex
                align-items center
                justify-content space-between
                width 210px
                .nav__button
                    a
                        display block
                        height 28px
                        &:not(:first-child)
                            margin-left 30px
                        .icon
                            display block
                            height 28px
                            svg
                                fill color-font
                        &:hover
                            svg
                                fill color-main-light
                        &:active
                            svg
                                fill color-main-dark
                    &.personal
                        svg
                            height 30px
                            width 26px
                    &.compare
                        a
                            svg
                                height 28px
                                width 38px
                                stroke color-font
                            &:hover
                                svg
                                    stroke color-main-light
                            &:active
                                svg
                                    stroke color-main-dark
                    &.favorites
                        svg
                            height 26px
                            width 29px
                    &.cart
                        svg
                            height 30px
                            width 29px
        & > nav.main
            height 50px
            border-bottom 1px solid gray
            .menu
                position relative
                display flex
                justify-content start
                width 100%
                .catalog__button
                    display flex
                    align-items center
                    justify-content left
                    height 50px
                    width 150px
                    background color-main
                    -webkit-user-select none
                    -moz-user-select none
                    -ms-user-select none
                    user-select none
                    cursor pointer
                    .icon
                        display flex
                        align-items center
                        justify-content center
                        height 50px
                        width 50px
                        svg
                            fill white
                            &.hamburger
                                display block
                                height 14px
                                width 18px
                            &.close
                                display none
                                height 28px
                                width 28px
                    .text
                        font-weight 500
                        color white
                    &.active
                        background color-main-dark
                        .icon
                            .hamburger
                                display none
                            .close
                                display block
                    &:hover
                        background color-main-light
                    &:active
                        background color-main-dark
                .main_menu
                    width calc(100% - 150px)
                    .menu__items
                        display flex
                        align-content center
                        justify-content space-around
                .dropdown_menu
                    display none
                    absolute(50px, auto, auto, 0)
                    width 100%
                    background white
                    box-shadow 0 4px 20px 0 rgba(black, 12%)
                    z-index 10
                    .menu__items
                        width 260px
                        padding 20px 0
                        border-right 1px solid gray
                        .menu__item
                            width 100%
                            padding-right 50px
                            .arrow
                                absolute(0, 0, auto, auto)
                                display flex
                                align-items center
                                justify-content center
                                height 50px
                                width 50px
                                svg
                                    width 11px
                                    height 5px
                                    transform rotate(90deg)
                            &.current,&.current,&.active,&.first:not(.submenu__item)
                                .arrow
                                    svg
                                        fill color-main
                                & + .submenu
                                    display flex
                            &.first
                                & + .submenu
                                    display flex
                            &:hover
                                .arrow
                                    svg
                                        fill color-main-light
                            &:active
                                .arrow
                                    svg
                                        fill color-main-dark
                                        
                        .submenu
                            absolute(0, 0)
                            display none
                            width calc(100% - 260px)
                            height 100%
                            padding 20px
                            background white
                            &.active
                                display flex
                            .submenu__items
                                display flex
                                flex-direction column
                                flex-wrap wrap
                                max-height 500px
                                width 100%
                                height 100%
                                +mq(xl-min, min)
                                    width calc(100% - 300px)
                                .menu__item
                                    width fit-content
                .menu__item
                    position relative
                    display flex
                    align-items center
                    justify-content start
                    height 50px
                    padding 0 20px
                    a, span
                        font-weight 500
                        color color-font
                    a
                        padding 10px 0
                    span
                        cursor default
                    &.active
                        a, span
                            color color-main
                    &.current, &.first:not(.submenu__item)
                        a, span
                            color color-main-dark
                    &:hover
                        a
                            color color-main-light
                    &:active
                        a
                            color color-main-dark
                    &.nonactive:not(.submenu__item):not(.active)
                        a, span
                            color color-font
            &.active
                box-shadow 0 4px 20px 0 rgba(black, 12%)
                .dropdown_menu
                    display block
    &.mobile
        & > .topbar
            background gray-light
            .container
                display flex
                align-items center
                justify-content space-between
                height 72px
                .location
                    display flex
                    align-items center
                    svg.icon
                        display block
                        height 26px
                        width 25px
                        fill color-main
                        margin-right 8px
                    .info
                        display flex
                        flex-direction column
                        .city
                            color color-main
                            font-weight 600
                            text-transform uppercase
                        .work_time
                            font-size 12px
                            color color-font-light
                .contact
                    width 160px
                    min-width 160px
                    margin-right 150px
                    +mq(sm-max)
                        margin-right 0
                    .phone
                        display block
                        margin-bottom 3px
                        font-size 17px
                        font-weight 600
                        color color-font
                    .callback
                        span
                            font-size 14px
                            font-weight 400
                            text-decoration underline
                            color color-main
                            cursor pointer
                            &:hover
                                color color-main-light
                            &:active
                                color color-main-dark
        & > .info
            border-top 1px solid gray
            border-bottom 1px solid gray
            .container
                position relative
                display flex
                align-items center
                justify-content space-between
                height 110px
                +mq(sm-max)
                    height 154px
                    padding 12px
                .flex
                    display flex
                    align-items center
                    justify-content space-between
                    +mq(sm-max)
                        align-items start
                        justify-content space-between
                        flex-direction column
                        place-self normal
                    &:last-child
                        align-items end
                    .logo
                        display flex
                        height 72px
                        .image
                            width 160px
                            height 72px
                            svg
                                width 160px
                                height 72px
                        .text
                            display flex
                            align-items center
                            margin-left 20px
                            span
                                display block
                                width 200px
                                padding-left 20px
                                border-left 1px solid gray
                                font-size 13px
                                font-weight 500
                                color rgba(color-font, 30%)
                    .search
                        margin-left 30px
                        +mq(sm-max)
                            margin 0
                        form
                            position relative
                            height 46px
                            input
                                height 46px
                                width 100%
                                min-width 184px
                                padding 16px 46px 16px 22px
                                background rgba(gray, 30%)
                                outline none
                                border 1px solid rgba(gray-dark, 30%)
                                round 3px
                                font-weight 500
                                color color-font-light
                                &:focus
                                    padding 15px 45px 15px 21px
                                    border 2px solid color-main
                                    background #fff
                                    color color-font
                            .search_icon
                                absolute(13px, 22px)
                                width 20px
                                height 20px
                                cursor pointer
                                svg
                                    width 20px
                                    height 20px
                                    fill color-font
                                &:hover
                                    svg
                                        fill color-main
                                &:active
                                    svg
                                        fill color-main-light
                    .btns
                        display flex
                        align-items center
                        justify-content space-between
                        margin auto
                        .nav__button
                            &:not(:first-child)
                                padding-left 24px
                            a
                                display block
                                height 28px
                                .icon
                                    display block
                                    height 28px
                                    svg
                                        fill color-font
                                &:hover
                                    svg
                                        fill color-main-light
                                &:active
                                    svg
                                        fill color-main-dark
                            &.personal
                                svg
                                    height 30px
                                    width 26px
                            &.compare
                                a
                                    svg
                                        height 28px
                                        width 38px
                                        stroke color-font
                                    &:hover
                                        svg
                                            stroke color-main-light
                                    &:active
                                        svg
                                            stroke color-main-dark
                            &.favorites
                                svg
                                    height 26px
                                    width 29px
                            &.cart
                                svg
                                    height 30px
                                    width 29px
                    nav.main
                        .catalog__button
                            absolute(-61px,12px,auto,auto)
                            display flex
                            align-items center
                            justify-content left
                            height 50px
                            width 130px
                            background color-main
                            round(3px)
                            -webkit-user-select none
                            -moz-user-select none
                            -ms-user-select none
                            user-select none
                            cursor pointer
                            z-index 11
                            +mq(sm-max)
                                position static
                                height 46px
                                margin-left 12px
                            .icon
                                display flex
                                align-items center
                                justify-content center
                                height 50px
                                width 50px
                                svg
                                    fill white
                                    &.hamburger
                                        display block
                                        height 14px
                                        width 18px
                                    &.close
                                        display none
                                        height 28px
                                        width 28px
                            .text
                                font-weight 500
                                color white
                            &.active
                                fixed(24px, 36px,auto,auto)
                                background color-main-dark
                                +mq(sm-max)
                                    fixed(12px, 12px,auto,auto)
                                .icon
                                    .hamburger
                                        display none
                                    .close
                                        display block
                            &:hover
                                background color-main-light
                            &:active
                                background color-main-dark
                        .dropdown_menu
                            display none
                            fixed(0, 0, auto, 0)
                            width 100%
                            background white
                            box-shadow 0 4px 20px 0 rgba(black, 12%)
                            z-index 10
                            +mq(sm-max)
                                padding-top 52px
                            .menu__items
                                width 260px
                                padding 20px 0
                                border-right 1px solid gray
                                +mq(sm-max)
                                    border none
                                    width 100%
                                .menu__item
                                    position relative
                                    display flex
                                    align-items center
                                    justify-content start
                                    width 100%
                                    height 50px
                                    padding 0 20px
                                    padding-right 50px
                                    a, span
                                        font-weight 500
                                        color color-font
                                    a
                                        padding 10px 0
                                    span
                                        cursor default
                                    &.current
                                        a, span
                                            color color-main
                                    &:hover
                                        a
                                            color color-main-light
                                    &:active
                                        a
                                            color color-main-dark
                                    .arrow
                                        absolute(0, 0, auto, auto)
                                        display none
                                        align-items center
                                        justify-content center
                                        height 50px
                                        width 50px
                                        svg
                                            width 11px
                                            height 5px
                                            transform rotate(90deg)
                                    &.current
                                        .arrow
                                            svg
                                                fill color-main
                                        & + .submenu
                                            display none
                                    &:hover
                                        .arrow
                                            svg
                                                fill color-main-light
                                    &:active
                                        .arrow
                                            svg
                                                fill color-main-dark
                                .submenu
                                    absolute(0, 0)
                                    display none
                                    width calc(100% - 260px)
                                    padding 20px
                                    background white
                                    &.active
                                        display none
                        &.active
                            box-shadow 0 4px 20px 0 rgba(black, 12%)
                            .dropdown_menu
                                display block