.call_request_2
    &__block
        position relative
        padding 24px
        background linear-gradient(100deg, color-main 0%, color-secondary 100%)
    &__form
        position relative
        width 100%
        padding 24px 10%
        .form
            &__items
                display flex
                align-items center
                justify-content space-between
            &__item
                display flex
                align-items center
                height 44px
                margin-bottom 24px
                
            &__input,&__button
                background none
                border none
                outline none
                round 12px
            &__input
                width 100%
                padding 12px
                background gray-light
                outline none
                border none
                &:focus
                    padding 11px
                    border 2px solid gray-dark
                &::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
                    opacity 80% /* Firefox */
                &.validation_error
                    padding 11px
                    border 2px solid color-danger
                &.validation_success
                    padding 11px
                    border 2px solid color-success
            &__button
                justify-content center
                height 44px
                width 100%
                text-align center
                background color-main
                font-weight 500
                cursor pointer
                color white
                &:hover
                    background color-main-light
                &:active
                    background color-main-dark
        .title
            color white
            font-size 16px
        .privacy
            .policy
                margin 0
                margin-top 12px
                font-size 12px
                color white
                a
                    color white
                    text-decoration underline
